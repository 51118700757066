<template>
  <div class="wameed-dashboard-page-content mb-5">
    <page-header
      :title="$t('common.reviews_projects')"
      :sub-title="$t('common.reviews_projects_subtitle')"
    />

    <!-- <div>
      <filter-header
        :content="content"
        @applyFilter="applyFilter"
        @resetFilter="resetFilter"
        @orderApplicants="orderApplicants"
      />
    </div> -->
    <section
      v-if="getAllReviews"
      class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
      "
    >
      <div class="d-flex flex-wrap flex-1 px-2">
        <b-col lg="12" md="12" class="px-0">
          <div class="w-table d-flex flex-column">
            <w-tables
              v-if="getAllReviews"
              :per-page="filterData.per_page"
              :items="getAllReviews"
              :fields="fields"
              :custimized-items="[
                { name: 'name' },
                { name: 'stars' },
                { name: 'created_at' },
              ]"
            >
              <template slot="name" slot-scope="{ data }">
                <div class="d-flex justify-center align-items-center">
                  <b-avatar variant="" size="50">
                    <img
                      v-if="data.item.photo"
                      :src="
                        data.item.photo.full_path + data.item.photo.file_name
                      "
                    />
                    <default-user-image-icon v-else />
                  </b-avatar>
                  <span class="text-font-main mx-3">{{ data.item.name }}</span>
                </div>
              </template>
              <template slot="stars" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">
                    <b-form-rating
                      v-model="data.item.stars"
                      dir="rtl"
                      class="cusstom_rating_table"
                      size="sm"
                      readonly
                    />
                  </span>
                </div>
              </template>
              <template slot="created_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">{{
                    formatedDate(data.item.created_at)
                  }}</span>
                </div>
              </template>
            </w-tables>
            <wameed-no-data
              v-if="getAllReviews && getAllReviews.length < 1"
              icon="nodata-icon"
              :title="$t('offers_page.no_data')"
              :sub-title="$t('offers_page.no_data_text')"
            />
          </div>
        </b-col>

        <b-col
          v-if="getAllReviews && getAllReviews.length > 0"
          lg="12"
          md="12"
          class="px-0"
        >
          <wameed-pagination
            v-model="filterData.page"
            :page="filterData.page"
            :total-items="getTotalReviews.totalItems"
            :per_page="filterData.per_page"
            @changePage="changePage"
            @changeCurrentPage="changeCurrentPage"
          />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import WameedPagination from '@/components/WameedPagination.vue';
import WameedNoData from '@/components/WameedNoData.vue';
import PageHeader from '@/components/PageHeader.vue';
import FilterHeader from '@/components/FilterHeader.vue';
// import ordersCard from "@/components/pages/orders/OrdersCard.vue";
import WTables from '@/components/pages/applicants/WTables.vue';
import WarningModal from '@/components/WarningModal.vue';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import TextArea from '@/components/TextArea.vue';
import TextInput from '@/components/TextInput.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';

export default {
  components: {
    WameedPagination,
    WameedNoData,
    PageHeader,
    FilterHeader,
    // ordersCard,
    WTables,
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
    TextInput,
    WameedDropdown,
  },
  data() {
    return {
      filterData: {
        type: '2',
        industry_preference: '',
        page: 1,
        per_page: 10,
        order_by: 'desc',
        search: '',
      },
      content: [
        {
          title: 'status',
          key: 'status',
          id: 'id',
          name: 'title',
          selectedData: [],
          data: [
            { title: 'قائمة', id: 1 },
            { title: 'تم التقديم', id: 2 },
            { title: 'منتهية', id: 9 },
          ],
        },
        {
          title: 'industry_preference',
          id: 'id',
          name: 'name',
          key: 'industry_preference',
          selectedData: [],
          data: [],
        },
      ],
      fields: [
        {
          key: 'name',
          label: this.$i18n.t('users_page.employee'),
          sortable: true,
        },
        {
          key: 'hr_job_title',
          label: this.$i18n.t('common.project'),
          sortable: true,
        },
        {
          key: 'stars',
          label: this.$i18n.t('common.rating'),
          sortable: true,
        },
        {
          key: 'comment',
          label: this.$i18n.t('common.comment'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$i18n.t('common.date'),
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAllReviews: 'getAllReviews',
      getTotalReviews: 'getTotalReviews',
    }),
  },
  watch: {},
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({
      loadReviews: 'loadReviews',
    }),
    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
      };
      this.loadData();
    },
    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },
    loadData() {
      this.loadReviews(this.filterData);
    },
    applyFilter() {
      this.content.forEach((item) => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    resetFilter() {
      this.content.forEach((item) => {
        item.selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },
    orderApplicants(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
  },
};
</script>
